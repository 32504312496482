import React, { useEffect, useRef, useState } from 'react';
import Header from '../Header/Header';
import { Outlet, useLocation } from 'react-router-dom';
import CustomFooter from '../Footer/Footer';
import { Content } from 'antd/es/layout/layout';
import '../Header/Header.css'
import { Image, Layout, Spin } from 'antd';
import StackedImage from '../../Images/LOGO/OTT 4 Me-stacked-dark.png'
import { Users } from '../../lib/Types';
import { WonderPush } from 'react-wonderpush';
import { getUser } from '../../lib/API';

const FrontMaster: React.FC = () => {
    const scrollRef = useRef<HTMLElement>();
    const title = [
        { id: "/dashboard", title: '' },
        { id: "/win", title: 'WIN / SECONDCHANCE' },
        { id: "/transaction-history", title: 'TRANSACTION HISTORY' },
        { id: "/find", title: 'FIND' },
        { id: "/settings", title: 'SETTINGS' },
        { id: "/buy-ott-voucher", title: 'BUY OTT VOUCHER' },
        { id: "/buy-payout-voucher", title: 'BUY' },
        // { id: "/buy-ott-voucher-steps", title: 'BUY OTT VOUCHER' },
        { id: "/providers", title: 'PROVIDERS' },
        { id: "/payment-status/:status", title: 'BUY OTT VOUCHER' },
        { id: "/user/profile", title: 'ACCOUNT INFO' },
        {
            id: "/enter-competition",
            title: "ENTER COMPETITION"
        },
        {
            id: "/lotto",
            title: "LOTTO"
        },
        {
            id: "/leagues",
            title: "LEAGUES"
        }

    ];

    const [user, setUser] = useState<Users>()

    useEffect(() => {
      
      getUser().then(r => {
          setUser(r);
      })
  }, []);

    const location = useLocation();
    const url = location.pathname;
    const selectedTitle = title.find((title) => url.startsWith(title.id))
    const titleName = selectedTitle ? selectedTitle.title : "BUY OTT VOUCHER";

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo({top: 0})
        }
    }, [url]);

    const options = {
        webKey: process.env.REACT_APP_WONDER_KEY,
        applicationName: "OTT4me",
        userId: user?.id,
        notificationDefaultUrl: "https://whitelabel-frontend-dev.azurewebsites.net/",
        notificationIcon: "./Images/OTT 4 Me-stacked-light.png"
    }

    return (
        user ?
            <WonderPush options={options}>
                <Layout className='page-wrapper'>
                    <Header title={titleName} />
                    <Content style={{paddingBottom: '87px'}} ref={scrollRef}>
                        <Outlet />
                    </Content>
                    <CustomFooter />
                </Layout>
                <div className='restrict-page-wrapper'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
                        <Image
                            src={StackedImage}
                            alt='ott_logo'
                            preview={false}
                            style={{ marginBottom: '2rem' }}
                        />
                        This site can only  be viewed on mobile devices. Please visit from a mobile device.
                    </div>
                </div>
            </WonderPush> : 
            <Spin indicator={<div className='global_loader' ></div>} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }} spinning={!user} tip="Loading..." />
    );
}

export default FrontMaster;