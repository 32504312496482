import {
    DribbbleOutlined,
    FileAddOutlined,
    FileDoneOutlined,
    HistoryOutlined,
    HomeOutlined,
    IdcardOutlined,
    ImportOutlined,
    LogoutOutlined,
    NotificationOutlined,
    ProfileOutlined,
    QuestionCircleOutlined,
    ReconciliationOutlined,
    SafetyOutlined,
    SettingOutlined,
    StarOutlined,
    TrophyOutlined,
    UserOutlined
} from "@ant-design/icons";
import { GiftIcon, HomeIcon, Logo, VouchersIcon, WinIcon } from "./Icons";

export const CustomerItems = [
    {
        key: 'dashboard',
        label: 'Home',
        link: '/dashboard',
        icon: HomeOutlined
    },
    {
        key: 'win',
        label: 'Win',
        link: '/win',
        icon: TrophyOutlined
    },
    {
        key: 'transaction-history',
        label: 'History',
        link: '/transaction-history',
        icon: HistoryOutlined
    },
    {
        key: 'coupons',
        label: 'Coupons',
        link: '/coupons',
        icon: FileAddOutlined
    },
    {
        key: 'redeem-coupon',
        label: 'Redeem Coupon',
        link: '#',
        icon: FileDoneOutlined
    },
    {
        key: 'lotto',
        label: 'Lotto',
        link: '/lotto',
        icon: StarOutlined
    },
    {
        key: 'football',
        label: 'Football',
        link: '/leagues',
        icon: DribbbleOutlined
    },
    {
        key: 'profile',
        label: 'Profile',
        link: '/user/profile',
        icon: ProfileOutlined
    },
    {
        key: 'faqs',
        label: 'FAQs',
        link: '#',
        icon: QuestionCircleOutlined
    },
    {
        key: 'privacy',
        label: 'Privacy',
        link: '#',
        icon: SafetyOutlined
    },
    {
        key: 'terms',
        label: 'Terms of Use',
        link: '#',
        icon: ReconciliationOutlined
    },
]

export const FooterItems = [
    {
        key: 'dashboard',
        label: 'Home',
        link: '/dashboard',
        icon: HomeIcon
    },
    {
        key: 'win',
        label: 'Win',
        link: '/win',
        icon: WinIcon
    },
    {
        key: 'providers',
        label: 'Buy',
        link: '/buy-ott-voucher',
        icon: Logo
    },
    {
        key: 'transaction-history',
        label: 'Vouchers',
        link: '/transaction-history',
        icon: VouchersIcon
    },
    {
        key: 'gifts',
        label: 'Gifts',
        link: '/gifts',
        icon: GiftIcon
    }
]

export const AdminItems = [
    {
        key: 'group_1',
        groupItems: [
            {
                key: 'dashboard',
                label: 'Dashboard',
                link: '/admin/dashboard',
                subs: []
            },
            {
                key: 'vouchers',
                label: 'Vouchers',
                link: '/admin/vouchers',
                subs: []
            },
            {
                key: 'coupons',
                label: 'Coupons',
                link: '/admin/coupons',
                subs: []
            },
            {
                key: 'transactions',
                label: 'Transactions',
                link: '/admin/transactions',
                subs: []
            },
            {
                key: 'payout',
                label: 'Payout',
                subs: [
                    {
                        key: 'providers',
                        label: 'Providers',
                        link: '/admin/payout/providers',
                    },
                    {
                        key: 'providers',
                        label: 'Bulk Providers',
                        link: '/admin/custom/providers',
                    }
                ]
            },
            {
                key: 'second_chance',
                label: 'Second Chance',
                subs: [
                    {
                        key: 'competitions',
                        label: 'Competitions',
                        link: '/admin/second_chance/competitions',
                    },
                    {
                        key: 'sponsors',
                        label: 'Sponsors',
                        link: '/admin/second_chance/sponsors',
                    },
                    {
                        key: 'surveys',
                        label: 'Surveys',
                        link: '/admin/second_chance/surveys',
                    }
                ]
            },
        ]
    },
    {
        key: 'group_2',
        groupItems: [
            {
                key: 'users',
                label: 'Users',
                subs: [
                    {
                        key: 'user',
                        label: 'User',
                        link: '/admin/users/user',
                    },
                    {
                        key: 'admins',
                        label: 'Technical Users',
                        link: '/admin/users/admins',
                    }
                ]
            },
            {
                key: 'roles',
                label: 'Roles',
                link: '/admin/roles',
                icon: IdcardOutlined,
                subs: []
            },
            {
                key: 'notifications',
                label: 'Notifications',
                icon: NotificationOutlined,
                subs: [
                    {
                        key: 'notifications_create',
                        label: 'Create',
                        link: '/admin/notifications_create',
                    },
                    {
                        key: 'notifications_view',
                        label: 'View',
                        link: '/admin/notifications_view',
                    }
                ]
            },
            {
                key: 'imports',
                label: 'Imports',
                icon: ImportOutlined,
                subs: [
                    {
                        key: 'imports',
                        label: 'Vouchers',
                        link: '/admin/imports/voucher',
                    },
                ]
            },
            {
                key: 'settings',
                label: 'Settings',
                icon: SettingOutlined,
                subs: [
                    {
                        key: 'designer',
                        label: 'Page Designer',
                        link: '/admin/settings/designer',
                    },
                    {
                        key: 'library',
                        label: 'Media Library',
                        link: '/admin/settings/library',
                    },
                    {
                        key: 'health',
                        label: 'Health Checks',
                        link: '/admin/settings/health',
                    },
                    {
                        key: 'settings_main',
                        label: 'Settings',
                        link: '/admin/settings_main',
                    }
                ]
            },
        ]
    },
    {
        key: 'group_3',
        groupItems: [
            {
                key: '10',
                label: 'My Profile',
                link: '#',
                icon: UserOutlined,
                subs: []
            },
            {
                key: '11',
                label: 'Sign Out',
                icon: LogoutOutlined,
                link: '#',
                subs: []
            }
        ]
    }
]