import { Spin } from 'antd';
import { Suspense, lazy, ComponentType } from 'react';

const Loader = <P extends object>(Component: ComponentType<P>): ComponentType<P> => (props: P) => (
    <Suspense fallback={<Spin indicator={<div className='global_loader' ></div>} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh'}} />}>
        <Component {...props} />
    </Suspense>
);

// Open Routes Pages
export const LandingOne = Loader(
    lazy(() => import('../Pages/LandingScreenOne'))
);

export const LandingTwo = Loader(
    lazy(() => import('../Pages/LandingScreenTwo'))
);

export const LandingThree = Loader(
    lazy(() => import('../Pages/LandingScreenThree'))
);

export const SliderProduct = Loader(
    lazy(() => import('../Components/Product/SliderProduct'))
);

export const About = Loader(
    lazy(() => import('../Pages/About'))
);

export const SentEmail = Loader(
    lazy(() => import('../Components/ForgetPasswordProcess/SentEmail'))
);

// Auth Routes Pages
export const LoginForm = Loader(
    lazy(() => import('../Pages/Auth/Login'))
);

export const Register = Loader(
    lazy(() => import('../Pages/Auth/Register/Register'))
);

export const Forgotpassword = Loader(
    lazy(() => import('../Pages/Auth/ForgetPassword'))
);

export const Success = Loader(
    lazy(() => import('../Components/ForgetPasswordProcess/SuccessPassword'))
);

export const Adminlogin = Loader(
    lazy(() => import('../Admin/Pages/Login'))
);

export const ResetPassword = Loader(
    lazy(() => import('../Components/ForgetPasswordProcess/ResetPassword'))
);

export const NewPassword = Loader(
    lazy(() => import('../Components/ForgetPasswordProcess/NewPassword'))
);

// Admin Routes Pages
export const UnAuthorized = Loader(
    lazy(() => import('../Admin/Common/UnAuthorized'))
);

export const Dashboard = Loader(
    lazy(() => import('../Admin/Pages/Dashboard'))
);

export const CustomProviders = Loader(
    lazy(() => import('../Admin/Pages/CustomProvider/CustomProviders'))
);

export const Imports = Loader(
    lazy(() => import('../Admin/Pages/BulkVouchers/Imports'))
);

export const Admin = Loader(
    lazy(() => import('../Admin/Pages/Admin/Admin'))
);

export const Voucher = Loader(
    lazy(() => import('../Admin/Pages/Voucher'))
);

export const Collect = Loader(
    lazy(() => import('../Admin/Pages/Collect'))
);

export const Payout = Loader(
    lazy(() => import('../Admin/Pages/Payout'))
);

export const Surveys = Loader(
    lazy(() => import('../Admin/Pages/Surveys/Surveys'))
);

export const SurveyDashboard = Loader(
    lazy(() => import('../Admin/Pages/Surveys/SurveyDashboard'))
);

export const SurveyForm = Loader(
    lazy(() => import('../Admin/Pages/Surveys/SurveyForm'))
);

export const User = Loader(
    lazy(() => import('../Admin/Pages/Users/User'))
);

export const Roles = Loader(
    lazy(() => import('../Admin/Pages/Roles/Roles'))
);

export const Competitions = Loader(
    lazy(() => import('../Admin/Pages/Competitions/Competitions'))
);

export const CompetitionForm = Loader(
    lazy(() => import('../Admin/Pages/Competitions/CompetitionForm'))
);

export const Notification = Loader(
    lazy(() => import('../Admin/Pages/Notifications'))
);

export const NotificationSettings = Loader(
    lazy(() => import('../Admin/Pages/Settings'))
);
export const HealthChecks = Loader(
    lazy(() => import('../Admin/Pages/HealthChecks'))
);

export const CreateNotification = Loader(
    lazy(() => import('../Admin/Pages/CreateNotifications'))
);

export const EditNotification = Loader(
    lazy(() => import('../Admin/Pages/Editnotification'))
);

export const CompetitionDetail = Loader(
    lazy(() => import('../Admin/Pages/Editnotification'))
);

export const LuckyDraw = Loader(
    lazy(() => import('../Admin/Pages/Competitions/LuckyDraw'))
);

export const CompEntries = Loader(
    lazy(() => import('../Admin/Pages/Competitions/CompetitionEntries'))
);

export const PageDesigner = Loader(
    lazy(() => import('../Admin/Pages/PageDesigner/PageDesigner'))
);

export const Providers = Loader(
    lazy(() => import('../Admin/Pages/PayoutProvider/Providers'))
);

export const Vouchers = Loader(
    lazy(() => import('../Admin/Pages/Vouchers/Voucher'))
);

export const MediaLibrary = Loader(
    lazy(() => import('../Admin/Pages/MediaLibrary/MediaLibrary'))
);

export const Coupons = Loader(
    lazy(() => import('../Admin/Pages/Coupon/Coupons'))
);

export const OTTSponsors = Loader(
    lazy(() => import('../Admin/Pages/OTTSponsor/OTTSponsors'))
);

export const Transactions = Loader(
    lazy(() => import('../Admin/Pages/Transaction/Transactions'))
);

// Client Routes Pages
export const LandingPage = Loader(
    lazy(() => import('../Pages/LandingPage'))
);

export const FailedTransaction = Loader(
    lazy(() => import('../Pages/TransactionResponses/FailedTransaction'))
);

export const CheckTransactionStatus = Loader(
    lazy(() => import('../Pages/TransactionResponses/CheckTransactionStatus'))
);

export const Win = Loader(
    lazy(() => import('../Pages/Win'))
);

export const TransactionHistory = Loader(
    lazy(() => import('../Pages/Vouchers/TransactionHistory'))
);

export const Settings = Loader(
    lazy(() => import('../Pages/Settings'))
);

export const BuyVoucherSteps = Loader(
    lazy(() => import('../Pages/BuyVoucherSteps'))
);

export const ClientProviders = Loader(
    lazy(() => import('../Pages/Providers/Providers'))
);

export const EnterCompetition = Loader(
    lazy(() => import('../Components/Slider/EnterCompetition'))
);

export const Survey = Loader(
    lazy(() => import('../Pages/Survey/Survey'))
);

export const Winning = Loader(
    lazy(() => import('../Components/Slider/Winning'))
);

export const OttBuyVourcherSteps = Loader(
    lazy(() => import('../Pages/OttBuyVourcherSteps'))
);

export const Leagues = Loader(
    lazy(() => import('../Pages/Leagues/Leagues'))
);

export const UserProfile = Loader(
    lazy(() => import('../Pages/UserProfile/UserProfile'))
);

export const LottoResults = Loader(
    lazy(() => import('../Pages/Lotto/LottoResults'))
);

export const LeaderBoard = Loader(
    lazy(() => import('../Pages/LeaderBoard/LeaderBoard'))
);

export const VoucherDetails = Loader(
    lazy(() => import('../Pages/Vouchers/VoucherDetails'))
);

export const ClientCoupons = Loader(
    lazy(() => import('../Pages/Vouchers/Coupons'))
);

export const LottoDrawResults = Loader(
    lazy(() => import('../Pages/Lotto/LottoDrawResults'))
);
